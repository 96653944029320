export const FOOTER_MENUS = [
  {
    title: "Frequently searched",
    items: [
      {
        label: "Murder Mystery for 5 players",
        path: "/murder-mystery-for-5-players",
      },
      {
        label: "Murder Mystery for 6 players",
        path: "/murder-mystery-for-6-players",
      },
      {
        label: "Murder Mystery for 7 players",
        path: "/murder-mystery-for-7-players",
      },
      {
        label: "Murder Mystery for 8 players",
        path: "/murder-mystery-for-8-players",
      },
      {
        label: "Murder Mystery in French",
        path: "https://www.culinario-mortale.fr",
      },
      {
        label: "Murder Mystery in German",
        path: "https://www.culinario-mortale.de",
      },
      {
        label: "All languages",
        path: "/languages",
      },
    ],
  },
  {
    title: "Our Murder Mysteries",
    items: [
      {
        label: "Shadow over Westongrove Manor",
        path: "/murder-mystery-games/shadow-over-westongrove-manor",
      },
      {
        label: "Deadly Fame",
        path: "/murder-mystery-games/deadly-fame",
      },
      {
        label: "Murder Overboard",
        path: "/murder-mystery-games/murder-overboard",
      },
      {
        label: "Expedition of Death",
        path: "/murder-mystery-games/expedition-of-death",
      },
      {
        label: "A Recipe for Death",
        path: "/murder-mystery-games/a-recipe-for-death",
      },
      {
        label: "Murder at the Grand Hotel",
        path: "/murder-mystery-games/murder-at-the-grand-hotel",
      },
    ],
  },
  {
    title: "Help & Support",
    items: [
      {
        label: "What is a Murder Mystery?",
        path: "/what-is-a-murder-mystery-party",
      },
      {
        label: "Rules",
        path: "/rules",
      },
      {
        label: "Frequent questions",
        path: "/frequent-questions",
      },
      {
        label: "Menu suggestions",
        path: "/recipes",
      },
      {
        label: "Download role descriptions",
        path: "/preparation",
      },
      {
        label: "Download lost materials",
        path: "/missing-materials",
      },
    ],
  },
  {
    title: "Information",
    items: [
      { label: "Terms and Conditions", path: "/terms-and-conditions" },
      { label: "Privacy Policy", path: "/privacy-policy" },
      { label: "Contact us", path: "/contact" },
    ],
  },
];
